<template>
  <div class="feedback-response-section-container">
    <div class="message-container">
      <strong>Thanks! </strong>
      <span class="header-text">
        {{ text.header }}
      </span>
    </div>
    <vue-multiselect
      v-model="selectedOption"
      class="feedback-options-dropdown"
      track-by="id"
      label="name"
      open-direction="bottom"
      :placeholder-text="text.optionsPlaceholder"
      :close-on-select="true"
      :options="clientFeedbackOptions"
      :show-labels="false"
      :multiple="false"
    >
      <template #option="{ option }">
        <div>
          {{ option.name }}
        </div>
      </template>
    </vue-multiselect>
    <div class="user-response-container">
      <b-form-textarea
        v-model="userInput"
        class="user-response-text-area"
        :disabled="disableSaveAndTextArea"
        type="text"
        rows="4"
        :maxlength="textAreaCharacterMax"
        :state="inputReachedLimit"
        :placeholder="getTextAreaPlaceholder()"
      />
      <div class="character-count-container">
        <span>
          {{ userInput.length }}/{{ textAreaCharacterMax }}
        </span>
      </div>
    </div>
    <div class="button-container">
      <b-button
        class="save-button"
        variant="primary"
        aria-label="save feedback"
        :disabled="disableSaveAndTextArea"
        @click="submitFeedback"
      >
        {{ text.saveButton }}
      </b-button>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FeedbackResponseSection',
  components: {
    VueMultiselect,
  },
  props: {
    sourceCategory: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      text: {
        header: ' Your feedback helps us do better. Would you like to leave more details?',
        optionsPlaceholder: 'Feedback options',
        textareaPlaceholder: 'Select from feedback options first.',
        saveButton: 'Save',
      },
      textAreaPlaceholder: {
        preOptionNegative: 'Select from feedback options first.',
        postOptionNegative: 'How can we improve? (Optional)',
        positive: 'Love the content! (Optional)',
      },
      userInput: '',
      selectedOption: '',
    }
  },
  computed: {
    ...mapGetters('stagelineClientFeedback', [
      'currentClientFeedbackObj',
      'clientFeedbackOptions',
      'clientFeedbackChoice',
      'textAreaCharacterMax',
    ]),
    inputReachedLimit() {
      return this.userInput.length < this.textAreaCharacterMax ? null : false
    },
    disableSaveAndTextArea() {
      return this.clientFeedbackChoice === 'thumbs-down' && !this.optionSelected
    },
    optionSelected() {
      return this.selectedOption.length !== 0
    },
  },
  methods: {
    ...mapActions('stagelineClientFeedback', [
      'setClientSavedFeedback',
      'updateClientFeedback',
      'saveClientFeedback',
    ]),
    getTextAreaPlaceholder() {
      switch(this.clientFeedbackChoice) {
        case 'thumbs-up':
          return this.textAreaPlaceholder.positive
        case 'thumbs-down':
          return this.optionSelected ?
            this.textAreaPlaceholder.postOptionNegative :
            this.textAreaPlaceholder.preOptionNegative
        default:
          return ''
      }
    },
    async submitFeedback() {
      const params = {
        comment: this.userInput,
        liked: this.clientFeedbackChoice === 'thumbs-up',
        options_id: this.selectedOption?.id,
        source_category: this.sourceCategory,
      }

      this.currentClientFeedbackObj ?
        await this.updateClientFeedback(params) :
        await this.saveClientFeedback(params)
    },
  },
}
</script>

<style scoped lang="scss">
.feedback-response-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;

  .message-container {
    margin-bottom: 1.5em;

    .header-text {
      display: inline-block;
    }
  }
  .feedback-options-dropdown {
    margin-bottom: 1em;
  }
  .user-response-container {
    margin-bottom: 1em;

    .user-response-text-area {
      height: 6.5em !important;
    }
  }
  .character-count-container {
    display: flex;
    justify-content: flex-end;
    color: gray;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
