var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "feedback-response-section-container" },
    [
      _c("div", { staticClass: "message-container" }, [
        _c("strong", [_vm._v("Thanks! ")]),
        _c("span", { staticClass: "header-text" }, [
          _vm._v("\n      " + _vm._s(_vm.text.header) + "\n    "),
        ]),
      ]),
      _c("vue-multiselect", {
        staticClass: "feedback-options-dropdown",
        attrs: {
          "track-by": "id",
          label: "name",
          "open-direction": "bottom",
          "placeholder-text": _vm.text.optionsPlaceholder,
          "close-on-select": true,
          options: _vm.clientFeedbackOptions,
          "show-labels": false,
          multiple: false,
        },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function ({ option }) {
              return [
                _c("div", [
                  _vm._v("\n        " + _vm._s(option.name) + "\n      "),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedOption,
          callback: function ($$v) {
            _vm.selectedOption = $$v
          },
          expression: "selectedOption",
        },
      }),
      _c(
        "div",
        { staticClass: "user-response-container" },
        [
          _c("b-form-textarea", {
            staticClass: "user-response-text-area",
            attrs: {
              disabled: _vm.disableSaveAndTextArea,
              type: "text",
              rows: "4",
              maxlength: _vm.textAreaCharacterMax,
              state: _vm.inputReachedLimit,
              placeholder: _vm.getTextAreaPlaceholder(),
            },
            model: {
              value: _vm.userInput,
              callback: function ($$v) {
                _vm.userInput = $$v
              },
              expression: "userInput",
            },
          }),
          _c("div", { staticClass: "character-count-container" }, [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.userInput.length) +
                  "/" +
                  _vm._s(_vm.textAreaCharacterMax) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c(
            "b-button",
            {
              staticClass: "save-button",
              attrs: {
                variant: "primary",
                "aria-label": "save feedback",
                disabled: _vm.disableSaveAndTextArea,
              },
              on: { click: _vm.submitFeedback },
            },
            [_vm._v("\n      " + _vm._s(_vm.text.saveButton) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }